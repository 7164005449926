@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Playwrite+CU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Playwrite+CU:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Protest+Guerrilla&display=swap');

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.crossfit-wod {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.navbar {
  border-bottom: 2px solid #747373;
  width: 90%;
  color: black;
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-family: "Protest Guerrilla", sans-serif;
  margin: auto !important;
  font-weight: bolder;
}

.navbar, .content, .footer {
  position: relative;
  z-index: 1;
}

.navbar, .title {
  margin: 0;
  font-size: 2.5rem;
  align-items: center;
}

.gym-icon {
  font-size: 1.5rem;
  margin-left: 2%;
  margin-right: 2%;
}

.visitor-count {
  background-color: black;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  margin: 10px auto;
}

.count {
  background-color: black;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  line-height: 40px;
}

.current-visitors {
  display: flex;
  flex-direction: column;
}

.current-web,
.visitors-text {
  margin: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.content-info {
  padding-bottom: 5%;
}

.join {
  color: #666;
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {
  color: #666;
  text-align: center;
  font-size: 1.5rem;
}

.workout-card {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.workoutid {
  opacity: 0;
}

h2 {
  color: #333;
  margin-top: 0;
  margin-bottom: 1rem;
}

.workout-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.workout-type, .workout-time {
  font-size: 0.9rem;
  color: #666;
}

.workout-description {
  white-space: pre-line;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.generate-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #454065;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.generate-button:hover {
  background-color: #0056b3;
}

.author-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.wod-author {
  margin: 0;
}

.author-info {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.author-details {
  margin-top: 2%;
}

.flag {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.author-flag-details {
  display: flex;
}

.username {
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.city {
  font-size: 0.8rem;
  margin-left: 0.2rem;
  color: gray;
}

.social-link {
  color: gray;
  text-decoration: none !important; 
  font-size: 0.9rem;
}

.social-link:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
}

.footer {
  color: white;
  text-align: center;
  font-size: 40%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1%;
  margin: 0 2%;
}

.footer h1 a {
  color: white;
}

.copyright {
  text-decoration: none;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.filter-button:hover {
  background-color: #454065;
  color: white;
}

.filter-button.active {
  background-color: #454065;
  color: white;
}


/* slider */
.background-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Adjust opacity as needed */
}

.background-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.background-slider img:nth-child(1) {
  animation: imageAnimation 28s linear infinite 0s;
}
.background-slider img:nth-child(2) {
  animation: imageAnimation 28s linear infinite 7s;
}
.background-slider img:nth-child(3) {
  animation: imageAnimation 28s linear infinite 14s;
}
.background-slider img:nth-child(4) {
  animation: imageAnimation 28s linear infinite 21s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {

  .navbar {
    width: 90%;
  }
  
  .background-slider img {
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .title {
    font-size: 55%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .visitor-count {
    border-radius: 6px;
    text-align: center;
    padding: 5px;
    margin: 15px auto;
  }
  
  .count {
    width: 25px;
    height: 25px;
    margin: auto;
    border-radius: 50%;
    line-height: 25px;
  }

  .current-web {
    font-size: 0.8rem;
  }

  .visitors-text {
    font-size: 1rem;
  }

  .content {
    justify-content: space-evenly;
  }

  .content-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .username,
  .social-link,
  .wod-author {
    font-size: 90%;
  }

  .city {
    font-size: 70%;
    margin-top: -5%;
    padding-top: 0;
    color: gray;
  }

  .author-details {
    display: flex;
    flex-direction: column;
  }

  .author-flag-details {
    display: flex;
  }

  .social-link a {
    margin-left: 20%;
  }

  .social-icons {
    display: flex;
    margin-top: -5%;
  }

  .footer {
    font-size: 30%;
  }

}
